import { Loading } from '@goodfynd/react-web.lib.ui';
import { useRouter } from 'next/router';
import React, { useCallback, useEffect, useState } from 'react';

const RoutingIndicator = () => {
  const [enRoute, toggleRouting] = useState(false);
  const router = useRouter();

  const handleRouteChange = useCallback(() => {
    toggleRouting(false);
  }, []);
  useEffect(() => {
    router.events.on('routeChangeComplete', handleRouteChange);
    router.events.on('routeChangeStart', () => toggleRouting(true));

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [handleRouteChange, router.events]);

  return enRoute ? <Loading type="line" /> : null;
};

export default RoutingIndicator;
